.lds_ring
  display: inline-block
  position: relative
  width: 100%
  height: 100%

.lds_ring div
  box-sizing: border-box
  display: block
  position: absolute
  margin: 2px
  border: 2px solid transparent
  border-radius: 50%
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
  border-color: #fff transparent transparent transparent

.lds_ring div:nth-child(1)
  animation-delay: -0.45s
.lds_ring div:nth-child(2)
  animation-delay: -0.3s
.lds_ring div:nth-child(3)
  animation-delay: -0.15s

@keyframes lds-ring
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

.fetch_loader
  display: flex
  justify-content: center
  align-items: center
  padding: 4px
  margin-bottom: 20px

  div
    width: 30px
    height: 30px

    div
      border: 3px solid #000000
      border-color: #000000 transparent transparent transparent
