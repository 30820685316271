.card
  background: rgba(249, 249, 249, 0.94)
  border-radius: 0 8px 8px 0
  display: flex
  margin-left: 80px

  &__photo
    width: 80px
    height: 160px
    position: relative

    img
      position: absolute
      width: 160px
      height: 160px
      left: -80px
      border-radius: 50%

  &__info
    display: flex
    flex-direction: column
    margin: auto
    width: min-content

    &__title
      font-size: 16px
      color: #979797

    &__name
      font:
        weight: bold
        size: 20px
      color: #000

.reviews
  display: flex
  flex-direction: column
  margin-top: 28px

  &__header
    display: flex
    justify-content: space-between
    margin-bottom: 22px

    &__title
      font:
        weight: bold
        size: 17px
      color: #000

    &__link
      font:
        weight: 300
        size: 17px
      color: #000000

.review_card
  background: rgba(249, 249, 249, 0.94)
  border-radius: 8px
  display: flex
  padding: 12px
  display: flex
  flex-direction: column

  &__text
    font-size: 14px
    margin-bottom: 12px

  &__info
    display: flex
    justify-content: space-between
    align-items: center

    &__rating
      color: #FFD600
      font-size: 18px

    &__author
      color: #000000

.master_info
  display: flex
  flex-direction: column
  margin-top: 28px

  &__phone
    display: flex
    align-items: center

    font:
      weight: 300
      size: 22px

    &__number
      margin-left: 8px

  &__address
    display: flex
    align-items: center
    margin-top: 16px
    font-size: 18px

    span
      margin-left: 8px
