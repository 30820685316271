.next_button
  width: 100%
  background-color: #000000
  border-radius: 14px
  font:
    weight: bold
    size: 17px
  color: #FFF
  padding: 13px
  text-align: center
  margin: 35px 0
  cursor: pointer

  &__disabled
    pointer-events: none
    opacity: 0.3

.loader
  display: flex
  justify-content: center
  align-items: center
  pointer-events: none

  & div
    width: 20px
    height: 20px
